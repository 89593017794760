import React, { useEffect, useState } from 'react'
import api from '../config/api'
import Loading from '../user/userWidgets/Loading'
import AadsCard from './adminWidgets/AadsCard'
import AdminHeader from './adminWidgets/AdminHeader'
import AnewAd from './adminWidgets/AnewAd'

const Aads = () => {
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [ads, setAds] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const x = window.localStorage.getItem('userType');
        if (x !== 'admin') {
            window.location.href = '/adminlogin'
        }
        api.get('/ads/all')
        .then((res) => {
            if (res.data.success) {
                setAds(res.data.data)
                setLoading(false)
            }
        })
        .catch(() => {
        })
    }, [refresh])
  return (
    <div>
        {
            loading?(
                <Loading />
            ):(
                <>
                    {
                        showModal?(
                            <AnewAd refresh={refresh} setRefresh={setRefresh} setShowModal={setShowModal} />
                        ):(null)
                    }
                    <AdminHeader />
                    <h3>Ads</h3>
                    <button onClick={() => setShowModal(true)} className='btn btn-success add_category_btn'>Add new ad <i className='icon-plus'></i> </button>
                    <div className='staff_page'>
                        <div className='row'>

                            {
                                ads.map((ad, index) => (
                                    <div key={index} className='col-md-4'>
                                        <AadsCard refresh={refresh} setRefresh={setRefresh} ad={ad} />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </>
            )
        }
    </div>
  )
}

export default Aads