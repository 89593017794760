import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../config/api';

const OrgUserRegister = () => {
    const {oid} = useParams();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConf, setPasswordConf] = useState('');
    const [phone, setPhone] = useState('');
    // control vars
    const [isRegisterd, setIsRegisterd] = useState(false);
    const register = () => {
        const userData = {
            username: username,
            password: password,
            phone: phone,
            orgId: oid
        }
        console.log('here')
        api.post('/org/newuser', userData)
        .then((res) => {
            if (res.data.success) {
                setIsRegisterd(true)
            }
        })
    }
  return (
    <div>
        <div className='org_login_form_container'>
        <br/>
        <center>
          <h3>Sign-up</h3>
          <h5>Org Name</h5>
        </center>
        <form>
            {
                !isRegisterd?(
                    <>
                        <div className="form-group">
                            <label>Username</label>
                            <input value={username} onChange={e => setUsername(e.target.value)} type="text" className="input_filed" placeholder='Username' />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input value={phone} onChange={e => setPhone(e.target.value)} type="text" className="input_filed" placeholder='Start with 0' />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="input_filed" placeholder='Password' />
                        </div>
                        <div className="form-group">
                            <label>Password Confirmation</label>
                            <input value={passwordConf} onChange={e => setPasswordConf(e.target.value)} type="password" className="input_filed" placeholder='Confirm password' />
                        </div>
                        <div>
                        </div>
                        <div>
                            <button type='button' onClick={() => register()} className="org_form_button"> register </button>
                        </div>
                    </>
                ):(
                    <>
                        <center>
                            <h4>THanks for choosing us</h4>
                            <div>
                                <Link  className="org_form_button link_to_btn" to='/orguser/login'>Go to login page</Link>
                            </div>
                        </center>
                    </>
                )
            }
        </form>
      </div>
    </div>
  )
}

export default OrgUserRegister