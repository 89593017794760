import React, { useState } from 'react'
import api from '../../config/api';

const EditOrgModal = ({setEditInfoModal, orgInfo, refresh, setRefresh}) => {
    const [orgName, setOrgName] = useState(orgInfo.orgName);
    const [orgAbout, setOrgAbout] = useState(orgInfo.orgAbout);
    const [userName, setUserName] = useState(orgInfo.username);
    const [password, setPassword] = useState(orgInfo.password)

    const updateOrg = () => {
        const orgData = {
            orgName: orgName,
            orgAbout: orgAbout,
            username: userName,
            password: password,
        }
        api.put('/admin/org/' + orgInfo.id, orgData)
        .then((res) => {
            if (res.data.success) {
                setRefresh(!refresh)
                setEditInfoModal(false)
            }
        })
    }

  return (
      
  <div className='modal_container'>
    <div className='edit_modal_frame'>
        <div className='edit_modal_header'>
            <button className='close_modal_button' onClick={() => setEditInfoModal(false)}> <i className='icon-cancel-1'></i> </button>
        </div>
        <h4>Edit orgnization info</h4>
        <br />
        <div className='row'>
            <div className='col-12'>

                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={orgName} onChange={(e) => setOrgName(e.target.value)} className="form-control" placeholder="Orgnization Name" />
                </div>

                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={orgAbout} onChange={(e) => setOrgAbout(e.target.value)} className="form-control" placeholder="About the orgnization" />
                </div>


                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Org admin username" />
                </div>


                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Org admin password" />
                </div>


            </div>
            <div className='col-md-12 col-sm-12'>
                <button onClick={() => updateOrg()} className="btn btn-primary edit_submit_btn">Submit</button>
            </div>
        </div>





    </div>
  </div>
  )
}

export default EditOrgModal