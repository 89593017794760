import React, { useEffect, useState } from 'react'
import api from '../../config/api';
import SurvyCompniesSelector from './SurvyCompniesSelector';

// const selectedOrgs = []
const NewSurvy = ({setShowSurvyModal, setRefresh, refresh}) => {
    const [title, setTitle] = useState('');
    const [about, setAbout] = useState('');
    const [link, setLink] = useState('');
    const [allOrgs, setAllOrgs] = useState([]);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    
    // function to handle selected orgs
    const handleOrg = (type, orgId) => {
        let orgs = selectedOrgs;
        if(type === 'add'){
            orgs.push(orgId)
        }else{
            orgs.splice(orgs.indexOf(5), 1);
        }
        setSelectedOrgs(orgs);
    } 

    const sendSurvy = () => {
        const survyData = {
            title: title,
            about: about,
            link: link,
            selectedOrgs: selectedOrgs
        }
        console.log(survyData)
        api.post('/admin/survy', survyData)
        .then((res) => {
            if (res.data.success) {
                setRefresh(!refresh)
                setShowSurvyModal(false)
            }
        })
    }

    // get all orgs for to select
    useEffect(() => {
        api.get('/admin/allorgs')
        .then((res) => {
            setAllOrgs(res.data.orgs)
        })
    }, [])
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setShowSurvyModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <h4>New Survy</h4>
            <br />
            <div className='row'>
                
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-quote-right'></i></div>
                        </div>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Survy title" />
                    </div>
                </div>                
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-menu'></i></div>
                        </div>
                        <input value={about} onChange={(e) => setAbout(e.target.value)} type="text" className="form-control" placeholder="Survy about" />
                    </div>
                </div>           
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-link'></i></div>
                        </div>
                        <input value={link} onChange={(e) => setLink(e.target.value)} type="text" className="form-control" placeholder="Survy link" />
                    </div>
                </div>
                <div className='col-12'>
                    <SurvyCompniesSelector selectedOrgs={selectedOrgs} handleOrg={handleOrg} allOrgs={allOrgs} />
                </div>

                <div className='col-md-12 col-sm-12'>
                    <button onClick={() => sendSurvy()} className="btn btn-primary edit_submit_btn">Submit</button>
                </div>
            </div>





        </div>
    </div>
  )
}

export default NewSurvy