import React, { useState } from 'react'
import api from '../../config/api'

const AeditCategory = ({refresh, setRefresh, category, setShowModal}) => {
    const [arabicName, setArabicName] = useState(category.arabicName)
    const [englishName, setEnglishName] = useState(category.englishName)

    const sendData = () => {
        let categoryData = {
            arabicName: arabicName,
            englishName: englishName
        }
        api.put('/category/editcat/' + category.id, categoryData)
        .then((res) => {
            console.log(res)
            setShowModal(false);
            setRefresh(!refresh)
        })
    }
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setShowModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <h4>Edit Info</h4>
            <br />
            <div className='row'>

                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-quote-right'>E</i></div>
                        </div>
                        <input value={englishName} onChange={e => setEnglishName(e.target.value)} type="text" className="form-control" placeholder="English name" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-quote-right'>ع</i></div>
                        </div>
                        <input value={arabicName} onChange={e => setArabicName(e.target.value)} type="text" className="form-control" placeholder="الأسم بالعربي" />
                    </div>
                </div>

                <div className='col-md-12 col-sm-12'>
                    <button onClick={() => sendData()} className="btn btn-primary edit_submit_btn">Submit</button>
                </div>
            </div>





        </div>
    </div>
  )
}

export default AeditCategory