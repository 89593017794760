import React from 'react'

const AskForSurvy = ({setSurvyModal, cleanSurvy}) => {
    const cleanSurvyFunction = () => {
        cleanSurvy();
        setSurvyModal(false);
    }
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div>
                <h3>Have some time to fill this servy</h3>
            </div>
            <div>
                <button className='send_bill_button ask_fro_survy_modal_botton'>Lets go</button>
            </div>
            <div>
                <button onClick={() => setSurvyModal(false)} className='send_bill_button ask_fro_survy_modal_botton_secodary'>Maybe later</button>
            </div>
            <div>
                <button onClick={() => cleanSurvyFunction()} className='send_bill_button ask_fro_survy_modal_botton_danger'>Sorry</button>
            </div>
        </div>
    </div>
  )
}

export default AskForSurvy