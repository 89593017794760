
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Aads from './components/admin/Aads';
import Acategory from './components/admin/Acategory';
import Ahome from './components/admin/Ahome';
import Alogin from './components/admin/Alogin';
import Amenu from './components/admin/Amenu';
import OneOrg from './components/admin/OneOrg';
import Staff from './components/admin/Staff';
import Survy from './components/admin/Survy';
import OrgHistory from './components/orgs/OrgHistory';
import OrgHome from './components/orgs/OrgHome';
import OrgLogin from './components/orgs/OrgLogin';
import OrgUser from './components/orgs/OrgUser';
import OrgUserHome from './components/orgs/OrgUserHome';
import OrgUserLogin from './components/orgs/OrgUserLogin';
import OrgUserRegister from './components/orgs/OrgUserRegister';
import Member from './components/user/Member';
import Menu from './components/user/Menu';


function App() {
  return (
    <>
      <center>
        <Router>
          <Switch>
            {/* GENERAL ROUTES */}
            <Route exact path='/' component={Menu} />
            <Route path='/member/:id' component={Member} />



            {/* MENU */}
            <Route path='/menu' component={Menu} />



            {/* ADMIN ROUTES */}
            <Route path="/adminlogin" component={Alogin} />
            <Route path='/admin/home' component={Ahome} />
            <Route path='/admin/menu' component={Amenu} />
            <Route path='/admin/category/:cid' component={Acategory} />
            <Route path='/admin/org/:id' component={OneOrg} />

            <Route path='/admin/staff' component={Staff} />
            <Route path='/admin/ads' component={Aads} />
            <Route path='/admin/survy' component={Survy} />


            {/* ORGS ROUTES */}
            <Route path='/org/orglogin' component={OrgLogin} />
            <Route path='/org/home' component={OrgHome} /> {/* Org admin */}
            <Route path='/org/user/:uid' component={OrgUser} /> {/* the admin checking the user */}
            <Route path='/org/register/:oid' component={OrgUserRegister} /> {/* used to make user regiter to an org based on the org id */}
            <Route path='/org/menu/:orgId' component={Menu} />
            <Route path='/org/history/:orgId' component={OrgHistory} />

            {/* ORG USERS ROUTES */}
            <Route path='/orguser/login' component={OrgUserLogin} />
            <Route path='/orguser/home' component={OrgUserHome} />
            
          </Switch>
        </Router>
      </center>
    </>
  );
}

export default App;
