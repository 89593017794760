import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../config/api'
import Footer from '../user/userWidgets/Footer'
import NewOrgUserModal from './orgWidgets/NewOrgUserModal'
import OrgHeader from './orgWidgets/OrgHeader'
import OrgUserCard from './orgWidgets/OrgUserCard'

const OrgHome = () => {
    const [newOrgUserModal, setNewOrgUserModal] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [orgInfo, setOrgInfo] = useState({})

    useEffect(() => {
        api.get('/org/info/' + window.localStorage.getItem('oadn') || 0)
        .then((res) => {
            if (res.data.success) {
                setOrgInfo(res.data.info)
            }
        })
    }, [])

    useEffect(() => {
        api.get('/org/allusers/' + window.localStorage.getItem('oadn') || 0)
        .then((res) => {
            if (res.data.success) {
                setAllUsers(res.data.users);
            }
        })
    }, [refresh])
  return (
    <div>
        {
            newOrgUserModal?(
                <NewOrgUserModal refresh={refresh} setRefresh={setRefresh} setNewOrgUserModal={setNewOrgUserModal} />
            ):null
        }
        <div>
            <OrgHeader />
        </div>
        <div className='org_info_section'>
            <h3> { orgInfo.orgName } </h3>
            <p> {orgInfo.orgAbout} </p>
        </div>
        <div>
            <center>
                {/* <button onClick={() => setNewOrgUserModal(true)} className='org_form_button add_new_org_user'>add new user</button> */}
                <Link to={'/org/history/' + orgInfo.id} className='link_to_btn org_form_button add_new_org_user history_button'>History</Link>
            </center>
                <br />
        </div>

        <div className="row org_admin_users_container">
            {
                allUsers.map((user, index) => (
                    <div key={index} className="col-md-3 col-sm-12">
                        <OrgUserCard user={user} />
                    </div>
                ))
            }
        </div>
        <br />
        <div>
            <Footer />
        </div>
    </div>
  )
}

export default OrgHome