import React from 'react'
import { Link } from 'react-router-dom'

const OrgUserCard = ({user}) => {
  return (
    <div className='org_admin_user_card'>
        <div>
            <h4> { user.username } </h4>
        </div>
        <div>
            <p> { user.createdAt } </p>
        </div>
        <div>
            <Link className='org_admin_user_card_link' to={'/org/user/' + user.id}>More info</Link>
        </div>
        <div>
            <button className='org_admin_user_action_button edit'> Edit</button>
            <button className='org_admin_user_action_button delete'> Delete</button>
        </div>
    </div>
  )
}

export default OrgUserCard