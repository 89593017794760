import React, { useEffect, useState } from 'react'
import api from '../../config/api';

const EditItemModal = ({setShowModal, itemId, refresh, setRefresh}) => {
    // form vars
    const [isActive, setIsActive] = useState(0)
    const [arabicName, setArabicName] = useState('')
    const [englishName, setEnglishName] = useState('')
    const [arabicOffer, setArabicOffer] = useState('')
    const [englishOffer, setEnglishOffer] = useState('')
    const [price, setPrice] = useState('0')
    const [cPrice, setCPrice] = useState('0')
    const [mPrice, setMPrice] = useState('0')
    const [showButton, setShowButton] = useState(true);
    
    // functions
    const sendData = () => {
        setShowButton(false)
        const itemData = {
            itemId: itemId,
            arabicName: arabicName,
            englishName: englishName,
            arabicOffer: arabicOffer,
            englishOffer: englishOffer,
            price: Number(price),
            cprice: Number(cPrice),
            mprice: Number(mPrice),
            isActive: isActive
        }
        console.log(itemData)
        // server request
        api.put('/category/updateitem', itemData)
        .then((res) => {
            console.log(res.data)
            if (res.data.success) {
                setRefresh(!refresh);
                setShowModal(false);
            }
        })
        setShowButton(true)
    }

    useEffect(() => {
        api.get('/category/item/' + itemId)
        .then((res) => {
            if (res.data.success) {
                setArabicName(res.data.data[0].arabicName)
                setEnglishName(res.data.data[0].englishName)
                setArabicOffer(res.data.data[0].arabicOffer)
                setEnglishOffer(res.data.data[0].englishOffer)
                setPrice(res.data.data[0].price)
                setCPrice(res.data.data[0].cprice)
                setMPrice(res.data.data[0].mprice)
                setIsActive(res.data.data[0].isActive)
            }
        })
    }, [])
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setShowModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <h4>Edit Info</h4>
            <br />
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-quote-right'>E</i></div>
                        </div>
                        <input value={englishName} onChange={e => setEnglishName(e.target.value)} type="text" className="form-control" placeholder="English name" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-quote-right'>ع</i></div>
                        </div>
                        <input value={arabicName} onChange={e => setArabicName(e.target.value)} type="text" className="form-control" placeholder="الإسم بالعربي" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-tag'>E</i></div>
                        </div>
                        <input value={englishOffer} onChange={e => setEnglishOffer(e.target.value)} type="text" className="form-control" placeholder="Product offers" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-tag'>ع</i></div>
                        </div>
                        <input value={arabicOffer} onChange={e => setArabicOffer(e.target.value)} type="text" className="form-control" placeholder="العروض الخاصة بالمنتج" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <i>Others price</i>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-dollar'></i></div>
                        </div>
                        <input value={price} onChange={e => setPrice(e.target.value)} type="text" className="form-control" placeholder="Price" />
                    </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                    <i>Chicken price</i>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-dollar'></i></div>
                        </div>
                        <input value={cPrice} onChange={e => setCPrice(e.target.value)} type="text" className="form-control" placeholder="Price" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <i>Meat price</i>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-dollar'></i></div>
                        </div>
                        <input value={mPrice} onChange={e => setMPrice(e.target.value)} type="text" className="form-control" placeholder="Price" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                {
                    isActive === 1?(
                        <button onClick={() => setIsActive(0)} class="btn btn-success status_item_btn">متوفر</button>
                    ):(
                        <button onClick={() => setIsActive(1)} class="btn btn-light status_item_btn">متوفر</button>
                    )
                }
                </div>
                <div className='col-md-12 col-sm-12'>
                    {
                        showButton?(
                            <button onClick={() => sendData()} className="btn btn-primary edit_submit_btn">Submit</button>
                        ):(
                            <p>Loading ...</p>
                        )
                    }
                </div>
            </div>





        </div>
    </div>
  )
}

export default EditItemModal