import React, { useEffect, useState } from 'react'

const LangSetter = ({setRefreshLanguage, refreshLanguage}) => {
    const [language, setLanguage] = useState('ar')
    const [refreshLang, setRefreshLang] = useState(false);
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setLanguage(window.localStorage.getItem('lang') || 'ar')
    }, [refreshLang])

    const setLang = (lang) => {
        window.localStorage.setItem('lang', lang);
        setRefreshLang(!refreshLang)
        setShowModal(false)
        setRefreshLanguage(!refreshLanguage)
    }
  return (
    <div>
        <button onClick={() => setShowModal(true)} className='lang_button'>{language === 'ar'? 'ع': 'En'}</button>

            {
                showModal?(
                    <div className='lang_modal'>
                        <div className='lang_selector_container'>
                            <button onClick={() => setLang('ar')} className={language === 'ar' || language === undefined? 'selected_languge_button selected_languge_button_active': 'selected_languge_button'}>عربي</button>
                            <button onClick={() => setLang('en')} className={language === 'en'? 'selected_languge_button selected_languge_button_active': 'selected_languge_button'}>English</button>
                        </div>
                    </div>
                ):(null)
            }
    </div>
  )
}

export default LangSetter