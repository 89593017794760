import React from 'react'
import redSeaAr from '../../../static/redSeaAr.png';
import brandLogo from '../../../static/brandLogo.png';

const Loading = () => {

  return (
    <div className='loading_modal'>
        <div className='box_container'>
            <img className='box_img1' src={brandLogo} alt='brand logo' />
            <br />
            <br />
            <img className='box_img1' src={redSeaAr} alt='brand logo' />
        </div>
    </div>
  )
}

export default Loading