import React, { useEffect } from 'react'

const OrgUserHistoryCard = ({user, usersOrders}) => {
    // useEffect(() => {
    // }, [])
  return (
    <div className='org_user_history_card'>
        <h3>{user.username}</h3>
        <div>
            {
                usersOrders.map((userOrder, index) => (
                    <div key={index}>
                        {
                            user.id === userOrder.userId?(
                                <div>
                                    {
                                        JSON.parse(userOrder.items).map((item, index) => (
                                            <p key={index}>{item.category} : {item.name} {item.type} : {item.amount} = {item.amount}</p>
                                        ))
                                    }
                                </div>
                            ):(null)
                        }
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default OrgUserHistoryCard