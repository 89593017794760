import React, { useState } from 'react'
import api from '../../config/api';

const NewOrgUserModal = ({setNewOrgUserModal, refresh, setRefresh}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const sendUserData = () => {
        const userData = {
            username: username,
            password: password,
            orgId: window.localStorage.getItem('oadn')
        }
        if ((userData.username.length > 3) || (userData.password.length > 3)) {
            api.post('/org/newuser', userData)
            .then((res) => {
                console.log(res.data)
            })
            setRefresh(!refresh)
            setNewOrgUserModal(false);
        }
        
    }
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setNewOrgUserModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <div>
                <h3>Add new user</h3>
            </div>
            <div>
                <div className='org_user_input_filed'>
                    <p>Username</p>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} type='text' placeholder='Username without space' />
                </div>
                <div className='org_user_input_filed'>
                    <p>Password</p>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type='text' placeholder='Only characters and numbers' />
                </div>
            </div>
            <div>
                <button onClick={() => sendUserData()} className='send_bill_button add_org_user_send' >Send</button>
            </div>
        </div>
    </div>
  )
}

export default NewOrgUserModal