import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../config/api'
import AeditCategory from './AeditCategory';

const AmenuCategoryCard = ({category, refresh, setRefresh}) => {
  const [showModal, setShowModal] = useState(false);

  const deleteCategory = (categoryId) => {
    api.delete('/category/category/' + categoryId)
    .then((res) => {
      if (res.data.success) {
        setRefresh(!refresh)
      }
    })
  }
  const editCategory = () => {
    setShowModal(true)
  }
  return (
    <div>
      {
        showModal?(
          <AeditCategory refresh={refresh} setRefresh={setRefresh} category={category} setShowModal={setShowModal}  />
        ):null
      }
        <div className="jumbotron">
          <div className='row'>
            <div className='col-2'>
              {
                category.categoryOrder === 1?(
                  null
                ):(
                  <button className='icon-left-open order_category_button'></button>
                )
              }
            </div>
            <div className='col-7'>
              <h4>{ category.arabicName }</h4>
            </div>

            <div className='col-2'>
              <button className='icon-right-open order_category_button'></button>
            </div>
            <div className='col-11'>
              <h4>{ category.englishName }</h4>
            </div>

          </div>
            <hr className="my-4"/>
            <button onClick={() => deleteCategory(category.id)} className='btn btn-danger delete_category_btn'><i className='icon-trash-2'></i> </button>
            <button onClick={() => editCategory()} className='btn btn-success delete_category_btn'><i className='icon-pencil-2'></i> </button>
            <Link className="btn btn-primary show_category_btn" to={'/admin/category/' + category.id}>Show more</Link>
        </div>
    </div>
  )
}

export default AmenuCategoryCard