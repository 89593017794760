import React, { useEffect, useState } from 'react'
import OrgHeader from './orgWidgets/OrgHeader';
import api from '../config/api';
import { Link, useHistory } from 'react-router-dom';
import AskForSurvy from './orgWidgets/AskForSurvy';

const OrgUserHome = () =>{
  const page = useHistory()
  const [userData, setUserData] = useState([]);
  const [survyModal, setSurvyModal] = useState(false);
  const [orgInfo, setOrgInfo] = useState([])
  useEffect(() => {
    let ousr = window.localStorage.getItem('ousr');
    let cut = window.localStorage.getItem('cut');
    if (cut === undefined || ousr === undefined) {
      window.location.href = '/'
    }
  }, [])

  useEffect(() => {
    api.get('/orguser/info/' + Number(window.localStorage.getItem('ousr')))
    .then((res) => {
      if (res.data.data.length === 0) {
        page.replace('/')
      }else{
        setUserData(res.data.data[0])
        if (res.data.data[0].survy > 0) {
          setSurvyModal(true);
        }
        api.get('/org/info/' + res.data.data[0].org)
        .then((res) => {
          setOrgInfo(res.data.info);
        })
      }
    })
  }, [])
  // cleaning up survy column
  const cleanSurvy = async() => {
    await api.get('/orguser/survy/clean/' + userData.id)
    .then((res) => {
      console.log(res.data)
    })
  }

  // Logout
  const logout = () => {
    window.localStorage.removeItem('cut');
    window.localStorage.removeItem('ousr');
    page.replace('/')
  }
    return (
      <div>
        <OrgHeader />
        {
          survyModal?(
            <AskForSurvy cleanSurvy={cleanSurvy} setSurvyModal={setSurvyModal} />
          ):(null)
        }

        <div className='org_user_home_buttons_container'>
          <div>
            <h3>{userData.username}</h3>
            {
              orgInfo.length !== 0?(
                <h4>{orgInfo.orgName}</h4>
              ):(null)
            }
          </div>
          <div>
            <Link to={'/org/menu/' + userData.org} className='link_to_btn org_form_button org_user_home_button'>Order as Emplyee</Link>
          </div>
          <div>
            <Link to='/menu' className='link_to_btn org_form_button org_user_home_button org_user_home_button_primary'>Order as individual</Link>
          </div>
          {/* <div>
            <Link to='/history' className='link_to_btn org_form_button org_user_home_button org_user_home_button_seconday'>History</Link>
          </div> */}
          <div>
            <button onClick={() => logout()} className='link_to_btn org_form_button org_user_home_button org_user_home_button_danger'>Logout</button>
          </div>
        </div>
      </div>
    )
}

export default OrgUserHome


















// import React, { useEffect, useState } from 'react'
// import BillModal from './orgWidgets/BillModal';
// import OrgHeader from './orgWidgets/OrgHeader';
// import OrgMenuItemCard from './orgWidgets/OrgMenuItemCard';
// import api from '../config/api';

// let cart = {};
// const OrgUserHome = () => {
//   const [showBill, setShowBill] = useState(false);
//   const [bill, setBill] = useState([]);
//   const [items, setItems] = useState([]);
//   const [today, setToday] = useState(false)

//   const handleCart = (op, obj) => {
//     if (op === 'add') {
//       cart[obj.id] = obj
//     }
//     else{

//       delete cart[obj.id]
//     }
//     console.log(cart)
//   }
//   // collect the selected products info
//   const setShowBillFunc = () => {
//     setShowBill(true);
//     let arrCart = Object.values(cart)
//     setBill(arrCart)
//   }
//   // fetch the menu
//   useEffect(() => {
//     api.get('/category/items/15')
//     .then((res) => {
//       if (res.data.success) {
//         setItems(res.data.data);
//       }
//     })
//   }, [])
//   return (
//     <div>
//       {
//         showBill?(
//           <BillModal setToday={setToday} bill={bill} setShowBill={setShowBill} />
//         ):null
//       }
//       <div>
//         <OrgHeader />
//       </div>
//       <div className='org_menu_container'>
//         {
//           today?(
//             <h3>Need something?</h3>
//           ):(
//             <>
//               <div className='row'>
//                 {
//                   items.map((product, index) => (
//                     <div key={index} className='col-md-2 col-sm-12'>
//                       <OrgMenuItemCard handleCart={handleCart} product={product} />
//                     </div>
//                   ))
//                 }
//               </div>
//             </>
//           )
//         }
//       </div>
//       {
//         !today?(
//           <div className='menu_controlers'>
//             <button onClick={() => setShowBillFunc()}>Done</button>
//           </div>
//         ):null
//       }
//     </div>
//   )
// }

// export default OrgUserHome