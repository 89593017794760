import React, { useState } from 'react'
import api from '../../config/api';

const NewOrgModal = ({setShowModal, refresh, setRefresh}) => {
    const [orgName, setOrgName] = useState('');
    const [orgAbout, setOrgAbout] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [time, setTime] = useState('');

    const sendToServer = () => {
        const orgInfo = {
            orgName: orgName,
            orgAbout: orgAbout,
            username: userName,
            password: password,
            time: time
        }
        console.log(time)
        api.post('/admin/neworg', orgInfo)
        .then((res) => {
            if (res.data.success) {
                setShowModal(false);
                setRefresh(!refresh);
            }
        })
    }

  return (
  <div className='modal_container'>
    <div className='edit_modal_frame'>
        <div className='edit_modal_header'>
            <button className='close_modal_button' onClick={() => setShowModal(false)}> <i className='icon-cancel-1'></i> </button>
        </div>
        <h4>Add new orgnization</h4>
        <br />
        <div className='row'>
            <div className='col-12'>

            <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={orgName} onChange={(e) => setOrgName(e.target.value)}  className="form-control" placeholder="Orgnization Name" />
                </div>


                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={orgAbout} onChange={(e) => setOrgAbout(e.target.value)} className="form-control" placeholder="About the orgnization" />
                </div>


                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Org admin username" />
                </div>


                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Org admin password" />
                </div>

                <div className="input-group mb-2 mr-sm-2 input_width">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className='icon-quote-right'></i></div>
                    </div>
                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="form-control" placeholder="Org admin password" />
                </div>


            </div>
            <div className='col-md-12 col-sm-12'>
                <button onClick={() => sendToServer("Hello its working")} className="btn btn-primary edit_submit_btn">Submit</button>
            </div>
        </div>





    </div>
    </div>
  )
}

export default NewOrgModal