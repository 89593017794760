import React, { useEffect, useState } from 'react'
import api from '../config/api';
import AdminHeader from './adminWidgets/AdminHeader'
import NewSurvy from './adminWidgets/NewSurvy';
import SurvyCard from './adminWidgets/SurvyCard';

const Survy = () => {
    const [showSurvyModal, setShowSurvyModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [survy, setSurvy] = useState([])

    useEffect(() => {
        api.get('/admin/survy/all')
        .then((res) => {
            if (res.data.success) {
                setSurvy(res.data.data);
            }
        })
    }, [refresh])

  return (
    <div>
        {
            showSurvyModal?(
                <NewSurvy refresh={refresh} setRefresh={setRefresh} setShowSurvyModal={setShowSurvyModal} />
            ):null
        }
        <div>
            <AdminHeader />
        </div>
        <div>
            <button  onClick={() => setShowSurvyModal(true)} className='btn btn-success'>Add new survy</button>
        </div>
        <div className='row'>
            {
                survy.map((sur, index) => (
                    <div key={index} className='col-md-4 col-sm-12'>
                        <SurvyCard sur={sur} />
                    </div>
                ))
            }

        </div>
    </div>
  )
}

export default Survy