import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import api from '../config/api';
import OrgHeader from './orgWidgets/OrgHeader'
import OrgUserHistoryCard from './orgWidgets/OrgUserHistoryCard';


const months = {'01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'Jun', '07': 'Jul', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'}
const OrgHistory = () => {
    const {orgId} = useParams()
    const [date, setDate] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [usersOrders, setUsersOrders] = useState([]);
    const [orgInfo, setOrgInfo] = useState([])

    useEffect(() => {
        api.get('/org/allusers/' + orgId)
        .then((res) => {
            setAllUsers(res.data.users);
        })
        const dateInfo = {
            day: Date().slice(8, 10),
            month: Date().slice(4, 7),
            year: Date().slice(11, 15)
        }
        api.post('/orguser/allorders/' + orgId, dateInfo)
        .then((res) => {
            setUsersOrders(res.data.data)
        })
        api.get('/org/info/' + orgId)
        .then((res) => {
            setOrgInfo(res.data.info);
        })
    }, [])

    const search = () => {
        let dateInfo = {
            year: date.split('-')[0],
            month: months[date.split('-')[1]],
            day: date.split('-')[2],
        }
        console.log(dateInfo)
        api.post('/orguser/allorders/' + orgId, dateInfo)
        .then((res) => {
            setUsersOrders(res.data.data)
        })
    }
  return (
    <div>
        <div>
            <OrgHeader />
        </div>
        <div>
            <h3> { orgInfo.orgName } </h3>
        </div>
        <div className='date_search_bar'>
            <input value={date} onChange={(e) => setDate(e.target.value)} type='date' />
            <button onClick={() => search()} className='icon-search-2'></button>
        </div>
        <div className='row'>
            {
                allUsers.map((user, index) => (
                    <div key={index} className='col-md-6 col-sm-12'>

                        <OrgUserHistoryCard usersOrders={usersOrders} user={user} />
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default OrgHistory