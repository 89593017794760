import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../config/api'
import AdminHeader from './adminWidgets/AdminHeader'
import AnewItemModal from './adminWidgets/AnewItemModal'
import EditItemModal from './adminWidgets/EditItemModal'

const Acategory = () => {
    const { cid } = useParams();
    const [showModal, setShowModal] = useState(false);
    // const [deleteModal, setDeleteModal] = useState(false);
    const [newItemModal, setNewItemModal] = useState(false);
    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [idOftheItem, setIdOfTheItem] = useState(0); 
    const [category, setCategory] = useState([])

    // api requests 
    useEffect(() => {
        api.get('/category/' + cid)
        .then((res) => {
            if (res.data.success) {
                setCategory(res.data.data[0])
            }
        })
    }, [])
    
    useEffect(() => {
        const x = window.localStorage.getItem('userType');
        if (x !== 'admin') {
            window.location.href = '/adminlogin'
        }
        // get items request
        api.get('/category/items/'+ cid)
        .then((res) => {
            if (res.data.success) {
                setItems(res.data.data);
            }
        })
    }, [refresh])
    // Delte one item
    const deleteItem = (itemId) => {
        console.log('delete item ' + itemId)
        
        if (window.confirm('sss')) {
            api.delete('/category/item/' + itemId)
            .then((res) => {
                if (res.data.success) {
                    setRefresh(!refresh)
                }
            })
            // setDeleteModal(true)
        }
        // return (<AdeleteModal refresh={refresh} setRefresh={setRefresh}  setDeleteModal={setDeleteModal} />)
    }

    // Edit item info
    const editItem = (itemId) => {
        setIdOfTheItem(itemId);
        setShowModal(true)
    }

  return (
    <div>
        {/* modal section */}
        {
            showModal?(
                <EditItemModal refresh={refresh} setRefresh={setRefresh} itemId={idOftheItem} setShowModal={setShowModal} />
            ):(null)
        }
        {/* {
            deleteModal?(
                <AdeleteModal refresh={refresh} setRefresh={setRefresh}  setDeleteModal={setDeleteModal} />
            ):(null)
        } */}
        {
            newItemModal?(
                <AnewItemModal cid={cid} refresh={refresh} setRefresh={setRefresh} setNewItemModal={setNewItemModal} />
            ):(null)
        }
        {/* end of modals section */}
        <AdminHeader />
        <div className='category_container_page'>
            <h3> {category.arabicName} </h3>
            <h3> {category.englishName} </h3>
            
            <button onClick={() => setNewItemModal(true)} className='btn btn-success add_category_btn'>Add new item <i className='icon-plus'></i> </button>
            
        </div>
        <div className='category_items_list_container'>

            <ul className="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-left row">
                            <div className='col-md-6 col-sm-12 row'>
                                <div className='col-6'>
                                    <p className='icon-quote-right'> Name </p>
                                </div>
                                <div className='col-6'>
                                    <p className='icon-star-2'> Offer </p>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12 row'>
                                <div  className='col-4'>
                                    <p className='icon-star-2'> Price </p>
                                </div>
                                <div  className='col-4'>
                                    <p className='icon-star-2'> Edit </p>
                                </div>
                                <div  className='col-4'>
                                    <p className='icon-star-2'> Delete </p>
                                </div>
                            </div>
                        </li>
                {/* loop over the items on the category */}

                {
                    items.map((item, index) => (
                        <li key={index} class="list-group-item d-flex justify-content-between align-items-center row">
                            <div className='col-md-6 col-sm-12 row'>
                                <div className='col-6'>
                                    <p className='icon-quote-right'> { item.arabicName } {!item.isActive?(<i className='icon-minus-circle'></i>):null} </p>
                                    <p className='icon-quote-right'> { item.englishName } </p>
                                </div>
                                <div className='col-6'>
                                    <p className='icon-star-2'> { item.arabicOffer } </p>
                                    <p className='icon-star-2'> { item.englishOffer } </p>
                                </div>
                            </div>

                            <div className='col-md-6 col-sm-12 row'>
                                <div className='col-4'>
                                    <span class="badge badge-primary badge-pill"> { item.price } </span>
                                </div>
                                <div className='col-4'>
                                    <button onClick={() => editItem(item.id)} className='item_action_button_on_category'>
                                        <i className='icon-pencil-2'></i>
                                    </button>
                                </div>
                                <div className='col-4'>
                                    <button onClick={() => deleteItem(item.id)} className='item_action_button_on_category'>
                                        <i className='icon-trash-2'></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))
                }

            </ul>

        </div>
    </div>
  )
}

export default Acategory