import React from 'react'

const SurvyCard = ({sur}) => {
  return (
    <div className='admin_survy_card'>
        <h3>{sur.title}</h3>
        <p>{sur.about}</p>
        <p>{sur.createdAt}</p>
        <div className='row'>
            <div className='col-4'>
                <button  className='btn btn-primary'>Open</button>
            </div>
            <div className='col-4'>
                <button  className='btn btn-success icon-pencil-2'></button>
            </div>
            <div className='col-4'>
                <button  className='btn btn-danger icon-trash-2'></button>
            </div>
        </div>
    </div>
  )
}

export default SurvyCard