import React, { useEffect, useState } from 'react'
import api from '../config/api'
import Loading from '../user/userWidgets/Loading'
import AdminHeader from './adminWidgets/AdminHeader'
import AnewModalMemeber from './adminWidgets/AnewModalMemeber'
import StaffCard from './adminWidgets/StaffCard'

const Staff = () => {
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const x = window.localStorage.getItem('userType');
        if (x !== 'admin') {
            window.location.href = '/adminlogin'
        }
        
        api.get('/staff/all')
        .then((res) => {
            if (res.data.success) {
                setStaff(res.data.data)
                setLoading(false)
            }
        })
    }, [refresh])
  return (
    <div>
        {
            loading?(
                <Loading />
            ):(
                <>
                    <AdminHeader />
                    {
                        showModal?(
                            <AnewModalMemeber setShowModal={setShowModal} refresh={refresh} setRefresh={setRefresh} />
                        ):(null)
                    }
                    <h3>Staff</h3>
                    <button onClick={() => setShowModal(true)} className='btn btn-success add_category_btn'>Add new member <i className='icon-plus'></i> </button>
                    <div className='staff_page'>
                        <div className='row'>

                            {
                                staff.map((member, index) => (
                                    <div key={index} className='col-md-4'>
                                        <StaffCard refresh={refresh} setRefresh={setRefresh} member={member} />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </>
            )
        }
    </div>
  )
}

export default Staff