import React, { useState } from 'react'
import api from '../../config/api';

const EditMemberModal = ({setShowModal, member, refresh, setRefresh}) => {
    const [arabicName, setArabicName] = useState(member.arabicName);
    const [englishName, setEnglishName] = useState(member.englishName);
    const [arabicJobDescription, setArabicJobDescription] = useState(member.arabicJobDescription)
    const [englishJobDescription, setEnglishJobDescription] = useState(member.englishJobDescription)
    const [email, setEmail] = useState(member.email)
    const [phone, setPhone] = useState(member.phone)
    const [showButton, setShowButton] = useState(true);
    const uploadToServer = () => {
        setShowButton(false)
        const memberData = {
            arabicName: arabicName,
            englishName: englishName,
            arabicJobDescription: arabicJobDescription,
            englishJobDescription: englishJobDescription,
            phone: phone,
            email: email
        }
        console.log(memberData)
        api.put('/staff/edit/' + member.id, memberData)
        .then((res) => {
            if (res.data.success) {
                setRefresh(!refresh)
                setShowModal(false)
            }
        })
        setShowButton(true)
    }
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setShowModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <h4>Edit Item</h4>
            <br />
            <div className='row'>


                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-user-1'>E</i></div>
                        </div>
                        <input value={englishName} onChange={(e) => setEnglishName(e.target.value)} type="text"  className="form-control" placeholder="English name" />
                    </div>
                </div>
                
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-user-1'>ع</i></div>
                        </div>
                        <input value={arabicName} onChange={(e) => setArabicName(e.target.value)} type="text"  className="form-control" placeholder="الأسم " />
                    </div>
                </div>
                
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-magic'>E</i></div>
                        </div>
                        <input value={englishJobDescription} onChange={(e) => setEnglishJobDescription(e.target.value)} type="text"  className="form-control" placeholder="Job description" />
                    </div>
                </div>
                
                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-magic'>ع</i></div>
                        </div>
                        <input value={arabicJobDescription} onChange={(e) => setArabicJobDescription(e.target.value)} type="text"  className="form-control" placeholder="المسمى الوظيفي" />
                    </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-phone'></i></div>
                        </div>
                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text"  className="form-control" placeholder="phone number" />
                    </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-mail-alt'></i></div>
                        </div>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="text"  className="form-control" placeholder="E-Mail" />
                    </div>
                </div>


                {/* File */}
                {/* <div className='col-md-6 col-sm-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-picture-2'></i></div>
                        </div>
                        <input accept="image/*" type="file"  className="form-control form-control-image" placeholder="Pic" />
                    </div>
                </div> */}
                
                <div className='col-md-6 col-sm-12'>
                </div>
                <div className='col-md-12 col-sm-12'>
                    {
                        showButton?(
                            <button onClick={() => uploadToServer()} className="btn btn-primary edit_submit_btn">Submit</button>
                        ):(
                            <p>Loading ...</p>
                        )
                    }
                </div>
            </div>

        </div>
    </div>
  )
}

export default EditMemberModal