import React, { useEffect } from 'react'
import AdminHeader from './adminWidgets/AdminHeader'
import Orgs from './Orgs';

const Ahome = () => {
  useEffect(() => {
    const x = window.localStorage.getItem('userType');
    if (x !== 'admin') {
        window.location.href = '/adminlogin'
    }
  }, [])
  return (
    <div>
        <AdminHeader />
        <h3>Welcome Admin</h3>
        <Orgs />
    </div>
  )
}

export default Ahome