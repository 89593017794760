import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import brandLogo from '../../static/brandLogo.png'
import redSeaLogoAr from '../../static/redSeaAr.png'
import api from '../config/api'
import Footer from './userWidgets/Footer'
import LangSetter from './userWidgets/LangSetter'
import Loading from './userWidgets/Loading'

const Member = () => {
    const { id } = useParams();
    const [member, setMember] = useState([]);
    const [found, setFound]  = useState(true)
    const [loading, setLoading] = useState(true);
    const [lang, setLang] = useState('ar')
    const [refreshLanguage, setRefreshLanguage] = useState(false)
    useEffect(() => {
        setLang(window.localStorage.getItem('lang') || 'ar');
    }, [refreshLanguage])
    useEffect(() => {
        api.get('/staff/member/' + id)
        .then((res) => {
            if (res.data.success && res.data.data.length > 0) {
                setMember(res.data.data[0])
                setLoading(false)
            }else{
                setFound(false)
                setLoading(false)
            }
        })
    }, [])

    // save contact function
    const saveContact = (name, phone, email) => {
        const saveBtn = document.createElement('a')
        // Get the contact information from the website
        var contact = {
            name: name,
            phone: phone,
            email: email
        };
        // create a vcard file
        var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work:" + contact.phone + "\nEMAIL;TYPE=INTERNET:" + contact.email + "\nEND:VCARD";
        var blob = new Blob([vcard], { type: "text/vcard" });

        var url = URL.createObjectURL(blob);
        saveBtn.href = url;
        saveBtn.download = contact.name + ".vcf";
        saveBtn.click()
    }
  return (
    <>
    {
        loading?(
            <Loading />
        ):(
            <>
            {
                found?(
                    <>
                        <div className='row menu_header member_menu_header'>
                            <div className='col-6 margin_zero logo_to_left'>
                                <img className='hedaer_logo' src={brandLogo} alt='brand logo' />
                            </div>
                            <div className='col-6 margin_zero logo_to_right'>
                                <img className='hedaer_logo' src={redSeaLogoAr} alt='brand logo' />
                            </div>
                        </div>
                        <div className='lang_button_continer_on_member'>
                            <LangSetter refreshLanguage={refreshLanguage} setRefreshLanguage={setRefreshLanguage} />
                        </div>
                        <div className='member_page_container'>
                            <br />
                            {
                                lang === 'ar'?(
                                    <>
                                        <h3 className='icon-user-1 member_name'> {member.arabicName} </h3>
                                        <div className='div_as_hr'></div>
                                        <p className='icon-magic member_Job'> { member.arabicJobDescription } </p>
                                        <p className='icon-mail-alt member_phone'> {member.email} </p>
                                        {
                                            member.phone.split(' ').map((phone, index) => (
                                                <div key={index}>
                                                    {
                                                        phone[0] === "#"?(
                                                            <p className='icon-whatsapp member_phone'><a href={'tel:' + phone.slice(1)}> {phone.slice(1)} </a>  <button onClick={() => saveContact(member.arabicName, phone.slice(1), member.email)} className='download_contact_button icon-user-add'></button> </p>
                                                        ):(
                                                            <p className='icon-phone member_phone'> <a href={'tel:' + phone}> {phone} </a> <button onClick={() => saveContact(member.arabicName, phone, member.email)} className='download_contact_button icon-user-add'></button> </p>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                        <div className='div_as_hr'></div>
                                        <p className='icon-location-1'>شارع جامعة البحر الأحمر شمال البوابة الرئيسية</p>
                                        <a href='https://maps.app.goo.gl/j7Szt3u9F5C7FntD6' target='_blank' className=' btn btn-primary maps_btn icon-location-1'> افتح الموقع على خرائط قوقل </a>
                                    </>
                                ):(
                                    <>
                                        <h3 className='icon-user-1 member_name'> {member.englishName} </h3>
                                        <div className='div_as_hr'></div>
                                        <p className='icon-magic member_Job'> { member.englishJobDescription } </p>
                                        <p className='icon-mail-alt member_phone'> {member.email} </p>
                                        {
                                            member.phone.split(' ').map((phone, index) => (
                                                <div key={index}>
                                                    {
                                                        phone[0] === "#"?(
                                                            <p className='icon-whatsapp member_phone'> <a href={'tel:' + phone.slice(1)}> {phone.slice(1)} </a>  <button onClick={() => saveContact(member.englishName, phone.slice(1), member.email)} className='download_contact_button icon-user-add'></button> </p>
                                                        ):(
                                                            <p className='icon-phone member_phone'> <a href={'tel:' + phone}> {phone} </a> <button onClick={() => saveContact(member.englishName, phone, member.email)}   className='download_contact_button icon-user-add'></button> </p>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                        <div className='div_as_hr'></div>
                                        <p className='icon-location-1'>North side of Red Sea University main gate</p>
                                        <a href='https://maps.app.goo.gl/j7Szt3u9F5C7FntD6' target='_blank' className=' btn btn-primary maps_btn icon-location-1'> Open location on google maps</a>
                                    </>
                                )
                            }
                        </div>
                        <div className='map_container'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1879.1857175659557!2d37.21890076559376!3d19.611411898490243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15d75520e8c5fd2f%3A0x9b999a2a86adc7fa!2sCharcoal&#39;s%20Burgers!5e0!3m2!1sen!2s!4v1699697168576!5m2!1sen!2s" style={{border: 'solid lightgray 1px', borderRadius: '10px'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <br />
                        <br />
                    </>
                ):(
                    <>
                    <h3>No User found</h3>
                    </>
                )
            }
            </>
        )
    }
    <Footer lang={lang} />
    </>
  )
}

export default Member