import React, { useEffect, useState } from 'react'

const SurvyCompniesSelector = ({allOrgs, handleOrg, selectedOrgs}) => {
    const [allOrgsTemp, setAllOrgsTemp] = useState(allOrgs);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        setAllOrgsTemp(allOrgs)
    }, [refresh])

    const handleOrgFunction = (type, orgId) => {
        handleOrg(type, orgId);
        setRefresh(!refresh)
    }
  return (
    <>
        {
            allOrgs.map((org, index) => (
                <>
                    <button key={index} onClick={() => handleOrgFunction(`${selectedOrgs.includes(org.id)?'remove' : 'add'}`, org.id)} className={`survy_selector_button ${selectedOrgs.includes(org.id)?'survy_selector_button_active' : ''}`}> { org.orgName } </button>
                </>
            ))
        }
    </>
  )
}

export default SurvyCompniesSelector