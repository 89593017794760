import React, { useEffect, useState } from 'react'
import pic1 from '../../../static/pic1.jpg';
import api from '../../config/api';

const AdsCard = () => {
  // const [ads, setAds] = useState([]);
  const [cuurentAd, setCurrentAd] = useState('')
  const [show, setShow] = useState(false)
  // const [listLen, setListLen] = useState(0)
  useEffect(() => {
    api.get('/ads/all/active')
    .then((res) => {
      if (res.data.success) {
        // setAds(res.data.data)
        if (res.data.data.length > 0) {
          setCurrentAd(res.data.data[0].picPath)
          setShow(true)
        }
        // setListLen(res.data.data.length);
      }
    })
  }, [])

  return (
    <div>
      {
        show?(
          <div style={{backgroundImage: `url(${cuurentAd})`}} className='ads_picture_container'></div>
        ):(null)
      }
    </div>
  )
}

export default AdsCard