import React from 'react'
import api from '../../config/api';


const headers = {"headers": {"Content-Type": "multipart/form-data"}}
const AnewAd = ({setShowModal, refresh, setRefresh}) => {
    const uploadToServer = () => {
        const file = [document.getElementsByClassName('form-control-image')[0].files[0]];

        const formData = new FormData();
        formData.append('image', file[0]);
        console.log(formData)
        api.post('/ads/new', formData, headers)
        .then((res) => {
            setShowModal(false)
            setRefresh(!refresh);
        })
    }
  return (
    <div className='modal_container'>
        <div className='edit_modal_frame'>
            <div className='edit_modal_header'>
                <button className='close_modal_button' onClick={() => setShowModal(false)}> <i className='icon-cancel-1'></i> </button>
            </div>
            <h4>New Item</h4>
            <br />
            <div className='row'>
                <div className='col-12'>
                    <div className="input-group mb-2 mr-sm-2 input_width">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className='icon-picture-2'></i></div>
                        </div>
                        <input accept="image/*" type="file"  className="form-control form-control-image" placeholder="English name" />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                {/* <button class="btn btn-success status_item_btn">Light</button>
                <button class="btn btn-light status_item_btn">Light</button> */}
                </div>
                <div className='col-md-12 col-sm-12'>
                    <button onClick={() => uploadToServer()} className="btn btn-primary edit_submit_btn">Submit</button>
                </div>
            </div>





        </div>
    </div>
  )
}

export default AnewAd