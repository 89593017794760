import React, { useEffect, useState } from 'react'
import api from '../config/api';
import Loading from '../user/userWidgets/Loading';
import AdminHeader from './adminWidgets/AdminHeader'
import AmenuCategoryCard from './adminWidgets/AmenuCategoryCard'
import AnewCatgoryModal from './adminWidgets/AnewCatgoryModal';

const Amenu = () => {
    const [orderingModal, setOrderingModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [categories, setCategories] = useState([]);
    const [errMsg, setErrMsg] = useState(false);
    const [loading, setLoading] = useState(true)

    // Handle the categories actions and requests
    useEffect(() => {
        const x = window.localStorage.getItem('userType');
        if (x !== 'admin') {
            window.location.href = '/adminlogin'
        }
        
        api.get('/category/all')
        .then((res) => {
            if (res.data.success) {
                setCategories(res.data.data);
                setLoading(false)
            }else{
                setErrMsg(true);
            }
        })
    }, [refresh])
  return (
    <div>
        {
            showModal?(
                <AnewCatgoryModal setRefresh={setRefresh} refresh={refresh} setShowModal={setShowModal} />
            ):(null)
        }
        <AdminHeader />
        <h3>Menu</h3>
        <button onClick={() => setShowModal(true)} className='btn btn-success add_category_btn'>Add new category <i className='icon-plus'></i> </button>
            {/* <button onClick={() => setOrderingModal(true)} className='btn btn-primary add_category_btn'>Ordering menu<i className='icon-plus'></i> </button> */}
        <div className='menu_cards_container row'>
            {
                !loading?(
                    <>
                        {
                            categories.length < 1 ?(
                                <h3>There is no categories</h3>
                            ):(
                                categories.map((category, index) => (
                                    <div key={index} className='col-md-4 col-sm-12'>
                                        <AmenuCategoryCard refresh={refresh} setRefresh={setRefresh} category={category} />
                                    </div>
                                ))
                            )
                        }
                    </>
                ):(
                    <Loading />
                )
            }
        </div>
    </div>
  )
}

export default Amenu