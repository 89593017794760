import React, { useState } from 'react'

const OneItemCard = ({item, lang, addToCard, removeFromCart, categoryName, categoryNameE}) => {
    const [onCart, setOnCart] = useState(false);
    const [mOnCart, setMOnCart] = useState(false);
    const addToCartFunction = (type) => {
        let price = 0;
        let ItemType = ''
        if (type === 'checkin') {
            price = item.cprice;
            ItemType = 'فراخ'
        }else{
            if (type === 'extra') {
                price = item.price
                ItemType = ''
            }
            else{
                price = item.mprice;
                ItemType = 'لحمة'
            }
        }
        addToCard(`${item.englishName} ${type} ${categoryNameE}`, {name: item.arabicName, category: categoryName, price: price, amount: 1, type: ItemType})
        if (type === 'checkin') {
            setOnCart(true)
        }else{
            setMOnCart(true)
        }
    }
    const removeFromCartFunction = (type) => {
        removeFromCart(`${item.englishName} ${type} ${categoryNameE}`)
        if (type === 'checkin') {
            setOnCart(false)
        }else{
            setMOnCart(false)
        }
    }
  return (
    <div className='menu_items_container'>
        <div className='menu_one_item'>
            {
                lang === 'ar'?(
                    <div className='row'>
                        {
                            item.cprice !== 0?(
                                <>
                                    <div className='col-8 lang_ar '>
                                        <p className='menu_item_name'> {item.arabicName} {!item.isActive?(<i className='icon-minus-circle'></i>):null} </p>
                                        <p className='menu_item_info'> {item.arabicOffer} </p>
                                    </div>
                                    <div className='col-2 lang_ar'>
                                        <p className='menu_item_price_title'> لحمة </p>
                                        <p className='menu_item_price'>{ item.mprice }</p>
                                        {/* {
                                            onCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('checkin')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('checkin')}></button>
                                            )
                                        } */}
                                    </div>
                                    <div className='col-2 lang_ar'>
                                        <p className='menu_item_price_title'> فراخ  </p>
                                        <p className='menu_item_price'>{ item.cprice }</p>
                                        {/* {
                                            mOnCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('meat')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('meat')}></button>
                                            )
                                        } */}
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className='col-10 lang_ar '>
                                        <p className='menu_item_name'> {item.arabicName} {!item.isActive?(<i className='icon-minus-circle'></i>):null} </p>
                                        <p className='menu_item_info'> {item.arabicOffer} </p>
                                    </div>
                                    <div className='col-2 lang_ar'>
                                        <p className='menu_item_price_title'>القيمة </p>
                                        <p className='menu_item_price'>{ item.price }</p>
                                        {/* {
                                            mOnCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('extra')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('extra')}></button>
                                            )
                                        } */}
                                    </div>
                                </>
                            )
                        }
                    </div>
                ):(
                    <div className='row align_left_card'>
                        {
                            item.cprice !== 0?(
                                <>
                                    <div className='col-2 lang_en'>
                                        <p className='menu_item_price_title'>Meat</p>
                                        <p className='menu_item_price'>{ item.mprice }</p>
                                        {/* {
                                            mOnCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('meat')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('meat')}></button>
                                            )
                                        } */}
                                    </div>
                                    <div className='col-2 lang_en'>
                                        <p className='menu_item_price_title'>Chicken</p>
                                        <p className='menu_item_price'>{ item.cprice }</p>
                                        {/* {
                                            onCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('checkin')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('checkin')}></button>
                                            )
                                        } */}
                                    </div>
                                    <div className='col-8 lang_en'>
                                        <p className='menu_item_name'> {!item.isActive?(<i className='icon-minus-circle'></i>):null}  {item.englishName} </p>
                                        <p className='menu_item_info'> {item.englishOffer} </p>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className='col-2 lang_en'>
                                        <p className='menu_item_price_title'>Price</p>
                                        <p className='menu_item_price'>{ item.price }</p>
                                        {/* {
                                            mOnCart?(
                                                <button className='icon-minus add_item_to_user_card' onClick={() => removeFromCartFunction('extra')}></button>
                                            ):(
                                                <button className='icon-plus add_item_to_user_card' onClick={() => addToCartFunction('extra')}></button>
                                            )
                                        } */}
                                    </div>
                                    <div className='col-10 lang_en'>
                                        <p className='menu_item_name'> {!item.isActive?(<i className='icon-minus-circle'></i>):null}  {item.englishName} </p>
                                        <p className='menu_item_info'> {item.englishOffer} </p>
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default OneItemCard