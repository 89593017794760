import React, { useEffect, useState } from 'react'
import api from '../../config/api'
import OneItemCard from './OneItemCard'

import burgerSplash from '../../../static/png/burgerSplash.png';
import burgerSpoiledSauce from '../../../static/png/burgerSpoiledSauce.png';
import compo from '../../../static/png/compo.png';


const MenuCard = ({category, lang, addToCard, removeFromCart}) => {
    const [items, setItems] = useState([])
    useEffect(() => {
        api.get('/category/items/' + category.id)
        .then((res) => {
            setItems(res.data.data)
        })
    }, [])
  return (
    <div className='menu_card lang_ar'>
        <div>
            <div>

                {
                    lang === 'ar'?(
                        <>
                            <div className='category_desc_pic category_desc_pic_ar'>
                                {
                                    category.id === 20?(
                                        <img src={burgerSplash} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                                {
                                    category.id === 21?(
                                        <img src={compo} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                                {
                                    category.id === 22?(
                                        <img src={burgerSpoiledSauce} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                            </div>
                            <h3 className='menu_title menu_title_ar'> { category.arabicName } </h3>
                        </>
                    ):(
                        <>
                            <div className='category_desc_pic category_desc_pic_en'>
                            {
                                    category.id === 20?(
                                        <img src={burgerSplash} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                                {
                                    category.id === 21?(
                                        <img src={compo} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                                {
                                    category.id === 22?(
                                        <img src={burgerSpoiledSauce} alt='category pic' className='category_pic' />
                                    ):(null)
                                }
                            </div>
                            <h3 className='menu_title menu_title_en'> { category.englishName } </h3>
                        </>
                    )
                }
            </div>
            <div>
                {
                    items.map((item, index) => (
                        <div key={index}>
                            <OneItemCard categoryNameE={category.englishName} categoryName={category.arabicName} addToCard={addToCard} removeFromCart={removeFromCart} lang={lang} item={item} />
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default MenuCard