import React, { useState } from 'react'
import api from '../config/api';
import { useHistory } from 'react-router-dom';
import brandLogo from '../../static/brandLogo.png'
import redSeaLogoAr from '../../static/redSeaAr.png'


const OrgLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const page = useHistory();
    const [errMsg, setErrMsg] = useState('')

    const login = () => {
      const userData = {
        username: username,
        password: password
      }
      api.post('/org/login', userData)
      .then((res) => {
        if (res.data.success) {
          window.localStorage.setItem('oadn', res.data.user.id);
          window.localStorage.setItem('cut', 'oadn');
          page.replace('/org/home');
          
        }else{
          if (res.data.msg === 'notFound') {
            setErrMsg("User not found")
          }else{
            setErrMsg("Wrong password")
          }
        }
      })
    }

  return (
    <>
      <div className='row '>
        <div className='col-6 margin_zero'>
            <img className='hedaer_logo' src={brandLogo} alt='brand logo' />
        </div>
        <div className='col-6 margin_zero'>
            <img className='hedaer_logo' src={redSeaLogoAr} alt='brand logo' />
        </div>
      </div>
      <div className='org_login_form_container'>
        <br/>
        <center>
          <h3>Org Login</h3>
        </center>
        <form>
          <div className="form-group">
              <label>Account</label>
              <input value={username} onChange={e => setUsername(e.target.value)} type="text" className="input_filed" placeholder='username'/>
          </div>
          <div className="form-group">
              <label>Password</label>
              <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="input_filed" placeholder='password'/>
          </div>
          <div>
            {errMsg}
          </div>
          <div>
            <button type='button' onClick={() => login()} className="org_form_button"> Login </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default OrgLogin