import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../config/api';
import AdminHeader from './adminWidgets/AdminHeader';
import NewOrgModal from './adminWidgets/NewOrgModal';

const Orgs = () => {
    const [allOrgs, setAllOrgs] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        api.get('/admin/allorgs')
        .then((res) => {
            setAllOrgs(res.data.orgs);

        })
    }, [refresh])
  return (
    <div>
        {
            showModal?(
                <NewOrgModal refresh={refresh} setRefresh={setRefresh} setShowModal={setShowModal} />
            ):(null)
        }
        <div>
            <h3>All orgs</h3>
        </div>
        <div>
            <button onClick={() => setShowModal(true)} className='btn btn-primary'>New organization</button>
        </div>
        <div className='row'>

            {
                allOrgs.map((org, index) => (

                    <div key={index} className='col-md-3 col-sm-12'>
                        <div className='admin_org_card'>
                            <h4 className='icon-quote-right'>{ org.orgName }</h4>
                            <p className='icon-doc-text-inv'>{org.orgAbout}</p>
                            <p className='icon-users'>Users : {org.users}</p>
                            <div>
                                <Link className='link_to_btn org_form_button add_new_org_user admin_org_more_info' to={'/admin/org/' + org.id}>More Info</Link>
                            </div>
                            <div>
                                <Link to={'/org/history/' + org.id} className='link_to_btn org_form_button add_new_org_user admin_history_button'>History</Link>
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
    </div>
  )
}

export default Orgs