import React from 'react'
import { Link } from 'react-router-dom'

const AdminHeader = () => {
    const logout = () => {
        window.localStorage.removeItem('userType');
        window.localStorage.removeItem('token');
        window.location.href = '/adminlogin'
    }
  return (
    <div>
        <div className='admin_home_btns row'>
            <div className='col-md-2 col-sm-12'>
                <Link to='/admin/home' className="btn btn-primary">Home <i className='icon-home-1'></i> </Link>
            </div>
            <div className='col-md-2 col-sm-12'>
                <Link to='/admin/menu' className="btn btn-primary">Menu <i className='icon-menu-1'></i> </Link>
            </div>
            <div className='col-md-2 col-sm-12'>
                <Link to='/admin/ads' className="btn btn-primary">Ads <i className='icon-picture-2'></i> </Link>
            </div>
            <div className='col-md-2 col-sm-12'>
                <Link to='/admin/staff' className="btn btn-primary">Staff <i className='icon-users'></i> </Link>
            </div>
            <div className='col-md-2 col-sm-12'>
                <Link to='/admin/survy' className="btn btn-primary">Survy <i className='icon-users'></i> </Link>
            </div>
            <div className='col-md-2 col-sm-12'>
                <button onClick={() => logout()} className='btn btn-danger'>Logout</button>
            </div>
        </div>
    </div>
  )
}

export default AdminHeader