import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../config/api';
import AdminHeader from './adminWidgets/AdminHeader';
import EditOrgModal from './adminWidgets/EditOrgModal';

const OneOrg = () => {
    const {id} = useParams();
    const [editInfoModal, setEditInfoModal] = useState(false);
    const [orgInfo, setOrgInfo] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [activeMailing, setActiveMailing] = useState([]);

    const getingJobs = () => {
        api.get('/admin/jobs')
        .then((res) => {
            console.log(res.data);
            setActiveMailing(res.data.jobs)
        })
    }
    useEffect(() => {
        api.get('/admin/org/' + id)
        .then((res) => {
            if (res.data.success) {
                setOrgInfo(res.data.org[0]);
            }
        })
        getingJobs();
    }, [refresh])

    const startMailingService = () => {
        api.post('/admin/org/service/start/' + orgInfo.id)
        .then((res) => {
            setRefresh(!refresh)
        })
        getingJobs();
    }
    const stopMailingService = () => {
        api.post('/admin/org/service/stop/' + orgInfo.id)
        .then((res) => {
            setRefresh(!refresh)
        })
        getingJobs();
    }
    
  return (
    <div>
        <div>
            <AdminHeader />
        </div>
        {
            editInfoModal?(
                <EditOrgModal refresh={refresh} setRefresh={setRefresh} orgInfo={orgInfo} setEditInfoModal={setEditInfoModal} />
            ):(null)
        }

        <table className='org_info_table'>
            <tbody>
                <tr>
                    <td> Users </td>
                    <td> &emsp; : { orgInfo.users } </td>
                </tr>
                <tr>
                    <td> user name </td>
                    <td> &emsp; : { orgInfo.username } </td>
                </tr>
                <tr>
                    <td> password </td>
                    <td> &emsp; : { orgInfo.password } </td>
                </tr>
                <tr>
                    <td> Mailing time </td>
                    <td> &emsp; : { orgInfo.mailingTime } </td>
                </tr>
            </tbody>
        </table>
        {
            activeMailing.includes(String(orgInfo.id))?(
                <>Mailing is active</>
            ):(
                <>Mailing is disapled</>
            )
        }
        <div>
            <button className='control_one_org' onClick={() => startMailingService()}>Start Mailing service</button>
        </div>
        <div>
            <button className='control_one_org control_one_org_red' onClick={() => stopMailingService()}>Stop Mailing service</button>
        </div>
        {/* <button className='btn btn-success' onClick={() => setEditInfoModal(true)} >edit Org info</button> */}
    </div>
  )
}

export default OneOrg