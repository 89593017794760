import React from 'react'

const Footer = ({lang}) => {
  return (
    <div className='footer_footer'>
        {
            lang === 'ar'?(
                <p> &copy; { Date().slice(11, 15) } كل الحقوق محفوظة - شاركلز بورتسودان</p>
            ):(
                <p>All right reserved - Charcolas Portsudan { Date().slice(11, 15) } &copy;</p>
            )
        }
    </div>
  )
}

export default Footer