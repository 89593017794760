import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import brandLogo from '../../static/brandLogo.png'
import redSeaLogoAr from '../../static/redSeaAr.png'
import api from '../config/api'
import AdsCard from './userWidgets/AdsCard'
import FinshDeal from './userWidgets/FinshDeal'
import Footer from './userWidgets/Footer'
import LangSetter from './userWidgets/LangSetter'
import Loading from './userWidgets/Loading'
import MenuCard from './userWidgets/MenuCard'


let cart = {};
const Menu = () => {
    const {orgId} = useParams();
    const [categories, setCategories] = useState([]);
    const [lang, setLang] = useState('ar');
    const [refreshLanguage, setRefreshLanguage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userDealModal, setUserDealModal] = useState(false);
    const [late, setLate] = useState(false);
    const [orgInfo, setOrgInfo] = useState([])

    // vars for the user data
    const [name, setName] = useState('');
    const [phones , setPhones] = useState('');
    const [location, setLocation] = useState('');
    // end of the user data vars

    useEffect(() => {
        setLang(window.localStorage.getItem('lang') || 'ar');
    }, [refreshLanguage])

    const copyData = async(text) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log('text copied to clipboard');
        } catch (error) {
            console.log('something went wrong');
        }
    }
    
    const copyToClipBoard = () => {
        if (name === '' || phones === '' || location === '') {
            console.log('fileds need to be field');
        }else{
            let text = `${name} \n${phones} \n${location} \n \n`;
            for(let key in cart){
                text += `${cart[key].category} ${cart[key].type} : ${cart[key].name} الكمية ${cart[key].amount} السعر ${cart[key].price * cart[key].amount} \n`
            }
            copyData(text);
        }
    }

    const sendOrderData = () => {
        let orderData = {
            orgId: Number(orgId),
            userId: Number(window.localStorage.getItem('ousr')),
            data:[]
        }
        for(let key in cart){
            orderData['data'].push(cart[key])
        }
        api.post('/orguser/user/order', orderData)
        .then((res) => {
            console.log(res.data)
        })
    }

    useEffect(() => {
        api.get('/category/all')
        .then((res) => {
            setCategories(res.data.data)
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        })
        // get org info
        api.get('/org/info/' + orgId)
        .then((res) => {
            let tempMailingTimeArr = res.data.info.mailingTime.split(':');
            // console.log(tempMailingTimeArr)
            let currentTime = String(Date()).slice(16, 21).split(':');
            setOrgInfo(res.data.info)
            if (Number(currentTime[0]) > Number(tempMailingTimeArr[0])) {
                // close
                setLate(true)
            }
            else{
                if ((Number(currentTime[0] === tempMailingTimeArr[0]) & (Number(currentTime[1]) > Number(tempMailingTimeArr[1]) ))) {
                    // close   
                    setLate(true)                 
                }
            }
        })
    }, [])
    const addToCard = (id, item) => {
        cart[id] = item;
    }
    const removeFromCart = (id) => {
        delete cart[id];
    }
    const showUserDealModal = () => {
        console.log(cart);
        setUserDealModal(true);
    }

  return (
    <div className='menu_background'>
        {
            late?(
                <>
                    <div className='late_section'>
                        <h3>You are late</h3>
                        <h4> The ording time ends at  {orgInfo.mailingTime } </h4>
                    </div>
                </>
            ):(
                <>
                    {/* <div className='done_deal_container'>
                        <button className='done_deal_container_button' onClick={() => showUserDealModal()}>إكمال المعاملة</button>
                        {
                            userDealModal?(
                                <FinshDeal sendOrderData={sendOrderData} orgId={orgId} copyToClipBoard={copyToClipBoard}  setName={setName} setPhones={setPhones} setLocation={setLocation} cart={cart} setUserDealModal={setUserDealModal} />
                            ):(null)
                        }
                    </div> */}
                    <div className='opacity_layer'>
                        {
                            loading?(
                                <Loading />
                            ):(null)
                        }
                        <div className='menu_header'>
                            <br />
                            <div>
                                <AdsCard />
                            </div>
                            <div className='row '>
                                <div className='col-6 margin_zero'>
                                    <img className='hedaer_logo' src={brandLogo} alt='brand logo' />
                                </div>
                                <div className='col-6 margin_zero'>
                                    <img className='hedaer_logo' src={redSeaLogoAr} alt='brand logo' />
                                </div>
                            </div>
                        </div>
                        <div className='menu_body'>
                            <div className='lang_button_div'>
                                <LangSetter refreshLanguage={refreshLanguage} setRefreshLanguage={setRefreshLanguage} />
                            </div>
                            {
                                lang === 'ar'?(
                                    <h3 className='area_title'>بورتسودان</h3>
                                ):(
                                    <h3 className='area_title'>Portsudan</h3>
                                )
                            }
                            <div className='row menu_card_container'>
                                {
                                    categories.map((category, index) => (
                                        <div className='col-md-4 col-sm-12' key={index}>
                                            <MenuCard addToCard={addToCard} removeFromCart={removeFromCart} lang={lang} category={category} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='footer_space'></div>
                    </div>
                </>
            )
        }
        <Footer lang={lang} />
    </div>
  )
}

export default Menu