import React, { useState } from 'react'
import userimage from '../../../static/co.jpg'
import api from '../../config/api'
import EditMemberModal from './EditMemberModal'

const StaffCard = ({refresh, setRefresh, member}) => {

  const [showModal, setShowModal] = useState(false)
  const deleteMemeber = (memberId) => {
    api.delete('/staff/' + memberId)
    .then((res) => {
      if (res.data.success) {
        setRefresh(!refresh)
      }
    })
  }
  const editMemberInfo = () => {
    setShowModal(true)
  }
  return (
    <div className="card">
        {
          showModal?(
            <EditMemberModal setRefresh={setRefresh} refresh={refresh} member={member} setShowModal={setShowModal} />
          ):(null)
        }
        <div className="card-body">
            <h6 className='icon-hashtag'> { member.id } </h6>
            <h5 className="card-title icon-user-1"> { member.arabicName } </h5>
            <h5 className="card-title icon-user-1"> { member.englishName } </h5>
            <p className="card-text icon-magic"> { member.arabicJobDescription } </p>
            <p className="card-text icon-magic"> { member.englishJobDescription } </p>
            {
              member.phone.split(' ').map((number, index) => (
                <p key={index} className='card-text icon-phone'> { number } </p>
              ))
            }
            <p className='card-text icon-mail-alt'> { member.email } </p>
            <div className='staff_card_actions'>
                <button onClick={() => deleteMemeber(member.id)} className='btn btn-danger delete_category_btn'><i className='icon-trash-2'></i> </button>
                <button onClick={() => editMemberInfo()} className="btn btn-primary" >Edit info</button>
            </div>
        </div>
    </div>
  )
}

export default StaffCard