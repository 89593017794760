import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import api from '../config/api'

const Alogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState(false);
    const [to, setTo] = useState('')
    const page = useHistory()
    useEffect(() => {
      const abortCont = new AbortController()
      const userType = localStorage.getItem('userType');
      if (userType === 'admin') {
        setTo('login')
        window.location.href = '/admin/home'
        return () => abortCont()
      }
    }, [])

    const login = () => {
        const abortCont = new AbortController()
        const adminData = {
            username: username,
            password: password
        }
        api.post('/admin/login', adminData)
        .then((res) => {
            if (res.data.success) {
                // redirect to the admin page if its match
                window.localStorage.setItem('userType', 'admin')
                window.localStorage.setItem('token', res.data.token)
                page.replace('/admin/home')
                return () => abortCont()
            }else{
                setErrMsg(true)
            }
        })
    }
  if (to !== 'login') {
    return (
      <div className='admin_login_form_container'>
        <h3>ADMIN LOGIN</h3>
  
        <br/>
        <div className="form-group">
          <label>Username</label>
          <input value={username} onChange={e => setUsername(e.target.value)} type="text" className="form-control"/>
        </div>
        <div className="form-group">
          <label>Password</label>
          <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="form-control"/>
        </div>
        {
          errMsg?(<>
              <div className="alert alert-danger small_text">
                  Wrong password or username
              </div>
          </>):(null)
        }
  
        <div>
          <button onClick={() => login()} className="btn btn-primary mb-2">Confirm identity</button>
        </div>
  
      </div>
    )
  }else{
    return(
      <></>
    )
  }
}

export default Alogin