import React from 'react'
import brandLogo from '../../../static/brandLogo.png'
import redSeaLogoAr from '../../../static/redSeaAr.png'

const OrgHeader = () => {
  return (
    <div>
        <div className='row '>
            <div className='col-6 margin_zero'>
                <img className='hedaer_logo' src={brandLogo} alt='brand logo' />
            </div>
            <div className='col-6 margin_zero'>
                <img className='hedaer_logo' src={redSeaLogoAr} alt='brand logo' />
            </div>
        </div>
    </div>
  )
}

export default OrgHeader